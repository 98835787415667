<template class="h-full">
  <div v-if="upload.processing" id="progressBar" class="fixed bottom-0 w-full bg-blue-500" style="z-index: -9999;"></div>
  <div v-if="!upload.processing">
    <div v-if="upload.error" id="error" class="mt-2 mb-2 text-red-500">{{ $t('upload.error') }}</div>
    <form id="uploadForm" @submit="processFile" action="">
      <div class="p-4">
        <label>
          <input type="file" class="hidden" accept="application/gzip, .gz, application/zip, .zip" @change="fileSelectChange" name="file" />
          <div id="fileSelectionBox" class="text-center p-8 rounded border-blue-500 border-4">
            <div id="fileSelectionText">{{ $t('upload.select') }}</div>
          </div>
        </label>
      </div>
      <div class="p-4">
        <label>
          <div>Full name of the device owner:</div>
          <div><input type="text" class="rounded border-2 p-2 w-full" v-model="name" name="name" placeholder="Full name of the device owner (e.g. Abdul Smith)"/></div>
        </label>
      </div>
      <div class="p-4">
        <label>
          <div>Email which will receive the results of the check:</div>
          <div><input type="email" class="rounded border-2 p-2 w-full" v-model="email" name="email" placeholder="user@example.org..."/></div>
        </label>
      </div>
      <div class="p-4">
        <label>
          <div>Please share any background information about the *person* impacted: e.g. Country, Organisation...</div>
          <div><textarea class="rounded border-2 p-2 w-full" v-model="personDetails" name="personDetails" placeholder="E.g. Country: Nepal, is an LGBTQ rights activist"></textarea></div>
        </label>
      </div>
  
      <div class="p-4">
        <label>
          <div>
            Is there any case-specific details or concerns about spyware?
            <br><br>
            Examples: 
            <ul class="list-disc p-4">
              <li>Received a Google/Apple threat notification</li>
              <li>Date on which a suspicious SMS was received</li>
              <li>Phone seized by police/at a border crossing on a particular date</li>
            </ul>
          </div>
          <div><textarea class="rounded border-2 p-2 w-full" v-model="caseDetails" name="caseDetails" placeholder="E.g. received Apple threat notification on 16th November 2023"></textarea></div>
        </label>
      </div>

      <div class="p-4"><input class="rounded w-full bg-blue-500 hover:bg-blue-400 cursor-pointer text-white text-xl font-bold p-2" type="submit" :value="$t('upload.upload')" :disabled="cantUpload"/></div>
    </form>
  </div>
  <div v-else>
    <div id="progressText" class="text-center text-3xl font-bold mt-8"></div>
  </div>
</template>

<script>
const axios = require('axios').default;
// var tar = require('tar-stream');
// var zlib = require('zlib');

export default {
  name: 'UploadForm',
  data: function() {
    return {
      selectedFile: null,
      parsedFile: null,
      name: '',
      email: '',
      personDetails: '',
      caseDetails: '',
      upload: {
        progress: 0,
        processing: false,
        success: null,
        error: null,
      },
    };
  },
  computed: {
    isFormProcessing() {
      return this.upload.processing;
    },
    cantUpload() {
        return (this.email == "") || this.selectedFile == null;
    }
  },
  methods: {
    fileSelectChange: function(event) {
      this.selectedFile = event.target.files[0];
      document.getElementById('fileSelectionText').classList.add('text-white');
      document.getElementById('fileSelectionText').innerHTML = '<b>Selected</b> <i>' + this.selectedFile.name + '</i>';
      document.getElementById('fileSelectionBox').classList.add('bg-blue-500');
    },
    processFile: function(event) {
      event.preventDefault();

      this.uploadFile();

      // var extract = tar.extract();
      // // var data = '';

      // extract.on('entry', function(header, stream, callback) {
      //   stream.on('data', function() {
      //     console.log(header.name);
      //   });

      //   stream.on('end', function() {
      //     console.log("END");
      //     callback();
      //   })
      // });


      // extract.on('finish', function() {
      //   console.log("FINISH");
      // });

      // // fs.createReadStream(this.selectedFile)
      // this.selectedFile.stream()
      //   .pipeTo(zlib.createGunzip())
      //   .pipeTo(extract);
      //   // .pipe(zlib.createGunzip()
      //   // .pipe(extract);
    },
    uploadFile: async function() {
      console.log("Uploading file...");

      var formData = new FormData();
      formData.append('name', this.name);
      formData.append('email', this.email);
      formData.append('person_details', this.personDetails);
      formData.append('case_details', this.caseDetails);
      formData.append('file', this.selectedFile);


      this.upload.progress = 0;
      this.upload.processing = true;

      console.log(...formData)

      axios.post(process.env.VUE_APP_API_ENDPOINT + '/api/uploader/', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        },
        onUploadProgress: (progressEvent) => {
          var percentCompleted = progressEvent.loaded / progressEvent.total;
          this.upload.progress = percentCompleted * 100;
          var percentString = Math.floor(this.upload.progress) + '%';
          document.getElementById("progressBar").style.height = this.upload.progress.toFixed(2) + '%';
          document.getElementById("progressText").innerHTML = percentString;
          console.log("Upload progress " + this.upload.progress);
        },
      })
      .then (response => {
        if (response.status != 200 || !response.data.success) {
          throw response;
        }
        this.upload.success = true;
        this.upload.processing = false;

        console.log("Created new upload with UUID:", response.data.uuid);

        if (process.env.VUE_APP_UPLOAD_ONLY == 1) {
          this.$router.push({name: 'ConfirmedPage', params: {'uuid': response.data.uuid}})
        } else {
          this.$router.push({name: 'ResultsPage', params: {'uuid': response.data.uuid}})
        }
      })
      .catch(err => {
        this.upload.err = err;
        this.upload.success = false;
        this.upload.processing = false;
      })
    },
  }
}
</script>
